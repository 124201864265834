<template>
  <div class="order">
    <el-row :gutter="10" style="width: 100%">
      <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="12">
        <vab-card class="order-card1" shadow="hover">
          <template #header>
            <vab-icon icon="user-3-line" />
            小程序活跃概况
          </template>
          <el-row class="order-card1-content">
            <template v-for="(item, index) in briskUserData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
      <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="12">
        <vab-card class="order-card2" shadow="hover">
          <template #header>
            <vab-icon icon="user-3-line" />
            新增用户概况
          </template>
          <el-row class="order-card2-content">
            <template v-for="(item, index) in newUserData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, toRefs } from 'vue'
  import _ from 'lodash'
  import VabCount from '@/extra/VabCount'

  export default defineComponent({
    components: { VabCount },
    props: {
      briskUserData: {
        type: Array,
        default: [],
      },
      newUserData: {
        type: Object,
        default: [],
      },
    },
    emits: ['confirm'],
    setup(props, { emit }) {
      const state = reactive({})
      return {
        ...toRefs(state),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .order {
    display: flex;
    justify-content: space-between;
    &-card1 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    &-card2 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    margin-bottom: $base-margin;
  }
</style>
